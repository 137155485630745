import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  ChakraProvider,
  Button,
  Textarea,
  useToast,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";
import BlackBox from "../Landing/InfoTopBox";
import BagCard from "./BagCard";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateCartQuantity,
  clearCart,
  crearPedidoCompleto,
  removeDiscount,
  applyDiscount,
} from "../Redux/Slice";
import axios from "axios";
import { Italic } from "lucide-react";

const API_BASE = process.env.REACT_APP_API_BASE;

// Función auxiliar para formatear precios
const formatPrice = (price) => Number(price).toFixed(2);

// Función auxiliar para formatear el número de teléfono
const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber.startsWith("+549")
    ? phoneNumber.substring(1)
    : `549${phoneNumber}`;
};

// Función para generar el mensaje del pedido
const generateOrderMessage = (
  pedidoCreado,
  pedidoArticulos,
  articulos,
  valores,
  useAlternativeAddress,
  alternativeAddress,
  user,
  direccion
) => {
  let message = `🛒 *Nuevo Pedido #${pedidoCreado.id}*\n\n`;

  // Agregar dirección de entrega
  const deliveryAddress = useAlternativeAddress
    ? alternativeAddress
    : user?.direccion || direccion;
  message += `📍 *Dirección de entrega${
    useAlternativeAddress ? " (Alternativa)" : ""
  }:*\n${deliveryAddress}\n\n`;

  // Agregar detalle de productos
  message += `📦 *Detalle del Pedido:*\n`;
  let subtotalGeneral = 0;

  pedidoArticulos.forEach((product, index) => {
    const cantidad = Number(product.quantity || product.stock);
    const precioUnitario = Number(
      product.precioFinal || product.precio_unitario
    );
    const subtotalProducto = cantidad * precioUnitario;
    subtotalGeneral += subtotalProducto;

    const articulo = articulos.find(
      (art) => art.id === Number(product.articleId || product.articulo?.id)
    );
    const valor = valores.find(
      (val) => val.id === Number(product.valorId || product.valor?.id)
    );

    message += `\n${index + 1}. *${articulo?.nombre || "Producto"}*\n`;
    message += `   • Presentación: ${valor?.attributes?.nombre || "N/A"}\n`;
    message += `   • Cantidad: ${cantidad} unidades\n`;
    message += `   • Precio: $${formatPrice(precioUnitario)}/u\n`;

    if (articulo?.descPorciento > 0) {
      message += `   • Descuento aplicado: ${articulo.DescPorciento}%\n`;
    }

    message += `   • Subtotal: $${formatPrice(subtotalProducto)}\n`;
  });

  // Agregar resumen del pedido
  message += `\n💰 *Resumen del Pedido:*\n`;
  message += `• Subtotal: $${formatPrice(subtotalGeneral)}\n`;
  message += `• Total Final: $${formatPrice(pedidoCreado.attributes.total)}\n`;
  message += `• Número de pedido: #${pedidoCreado.id}\n`;

  // Agregar notas adicionales si existen
  if (pedidoCreado.attributes.codigos_de_descuentos?.length > 0) {
    const discount = pedidoCreado.attributes.codigos_de_descuentos[0];
    message += `• Descuento aplicado: ${discount.code} (${discount.discountPercentage}%)\n`;
    message += `• Ahorro total: $${(
      (subtotalGeneral * discount.discountPercentage) /
      100
    ).toFixed(2)}\n`;
  }

  message += `\n¡Gracias por tu compra! 🙂`;

  return message;
};

const handleWhatsAppRedirect = (formattedPhone, message) => {
  // Check if device is iOS
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Encode message for URL
  const encodedMessage = encodeURIComponent(message);

  // Create URL based on device
  const url = isIOS
    ? `whatsapp://send?phone=${formattedPhone}&text=${encodedMessage}`
    : `https://api.whatsapp.com/send?phone=${formattedPhone}&text=${encodedMessage}`;

  // Try to open WhatsApp
  window.location.href = url;

  // Fallback for desktop or if direct link fails
  setTimeout(() => {
    const webUrl = `https://web.whatsapp.com/send?phone=${formattedPhone}&text=${encodedMessage}`;
    window.open(webUrl, "_blank");
  }, 1000);
};

// New function to validate discount code
const validateDiscountCode = async (code, currentTotal) => {
  try {
    const response = await axios.post(
      `${API_BASE}/api/discount-codes/validate`,
      { code, total: currentTotal }
    );

    if (response.data.isValid) {
      return {
        valid: true,
        percentage: response.data.percentage,
        codeId: response.data.codeId,
      };
    }
    return { valid: false, error: response.data.error };
  } catch (error) {
    return { valid: false, error: "Error al validar el código" };
  }
};

export default function Carrito() {
  const scrollRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [detalle, setDetalle] = useState("");
  const [direccion, setDireccion] = useState("");
  const [direccionError, setDireccionError] = useState("");
  const { pedidoActual, token, user } = useSelector((state) => ({
    pedidoActual: state.pedidoActual,
    token: state.token,
    user: state.user,
  }));
  const [useAlternativeAddress, setUseAlternativeAddress] = useState(false);
  const [alternativeAddress, setAlternativeAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const articulos = useSelector((state) => state.articulos);
  const valores = useSelector((state) => state.valores);
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeError, setDiscountCodeError] = useState("");
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  // Inicializar la dirección con la del usuario si existe
  useEffect(() => {
    if (user?.direccion) {
      setDireccion(user.direccion);
    }
  }, [user]);

  const calculateSubtotal = () => {
    if (!pedidoActual?.attributes?.pedido_articulos?.length) {
      return 0;
    }

    return pedidoActual.attributes.pedido_articulos.reduce((total, item) => {
      const cantidad = Number(item.quantity || item.cantidad);
      const precioUnitario = Number(item.precioFinal || item.precio_unitario);
      return total + cantidad * precioUnitario;
    }, 0);
  };

  const handleApplyDiscountCode = async () => {
    if (!discountCode.trim()) {
      setDiscountCodeError("Ingresa un código de descuento");
      return;
    }

    setIsLoading(true);
    try {
      const currentTotal = calculateSubtotal(); // Calcular subtotal sin descuentos
      const validation = await validateDiscountCode(discountCode, currentTotal);

      if (validation.valid) {
        const discountValue = currentTotal * (validation.percentage / 100);
        const newTotal = currentTotal - discountValue;

        dispatch(
          applyDiscount({
            discountCode,
            codeId: validation.codeId,
            discountPercentage: validation.percentage,
            discountAmount: discountValue,
            newTotal,
          })
        );

        setDiscountAmount(discountValue);
        setIsDiscountApplied(true);
        setDiscountCodeError("");

        toast({
          title: "Descuento aplicado",
          description: `Se aplicó un ${validation.percentage}% de descuento`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setDiscountCodeError(validation.error);
        setIsDiscountApplied(false);
      }
    } catch (error) {
      setDiscountCodeError("Error al validar el código");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveDiscount = () => {
    // Revert to original total in Redux state
    dispatch(removeDiscount());
    setDiscountCode("");
    setIsDiscountApplied(false);
    setDiscountAmount(0);
    setDiscountCodeError("");
  };

  const handleQuantityChange = (articleId, valorId, quantity) => {
    dispatch(updateCartQuantity({ articleId, valorId, quantity }));
  };

  const validateAddress = () => {
    const addressToValidate = useAlternativeAddress
      ? alternativeAddress
      : user?.direccion || direccion;
    if (!addressToValidate.trim()) {
      setAddressError("La dirección es obligatoria");
      return false;
    }
    setAddressError("");
    return true;
  };

  const handleFinalizarPedido = async () => {
    if (!validateAddress()) {
      toast({
        title: "Dirección requerida",
        description: "Por favor ingresa una dirección de entrega válida",
        status: "warning",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      return;
    }

    if (!pedidoActual?.attributes?.pedido_articulos?.length) {
      toast({
        title: "Carrito vacío",
        description:
          "No se puede finalizar el pedido porque el carrito está vacío.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      return;
    }

    setIsLoading(true);

    const pedidoData = {
      comercioId: 1,
      articulos: pedidoActual.attributes.pedido_articulos.map((item) => {
        const articulo = articulos.find(a => a.id === Number(item.articleId || item.articulo?.id));
        const valor = valores.find(v => v.id === Number(item.valorId || item.valor?.id));
        
        return {
          articuloId: Number(item.articleId || item.articulo?.id),
          valorId: Number(item.valorId || item.valor?.id),
          cantidad: Number(item.quantity || item.cantidad),
          precio: Number(item.precioFinal || item.precio_unitario),
          // Información adicional para el cálculo de stock
          medida: articulo?.Medida,
          grsPorcion: valor?.attributes?.GrsPorcion
        };
      }),
      detalle: detalle || "",
      direccion: useAlternativeAddress
        ? alternativeAddress
        : user?.direccion || direccion,
    };

    try {
      const pedidoCreado = await dispatch(
        crearPedidoCompleto(pedidoData)
      ).unwrap();

      // Generar el mensaje usando la función auxiliar
      const message = generateOrderMessage(
        pedidoCreado,
        pedidoActual.attributes.pedido_articulos,
        articulos,
        valores,
        useAlternativeAddress,
        alternativeAddress,
        user,
        direccion
      );

      // Manejar la redirección a WhatsApp
      setTimeout(() => {
        const phoneNumber = "2914228046";
        const formattedPhone = formatPhoneNumber(phoneNumber);
        handleWhatsAppRedirect(formattedPhone, message);
      }, 0);

      navigate(`/Exito/${pedidoCreado.id}`);
      toast({
        title: "Pedido creado con éxito",
        description: "Tu pedido ha sido procesado correctamente",
        status: "success",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });

      dispatch(clearCart());
    } catch (error) {
      if (error.message?.includes("No hay token")) {
        navigate("/Login");
        return;
      }
      console.error("Error en el procesamiento:", error);
      toast({
        title: "Error en el procesamiento",
        description: error.message || "Error al crear el pedido",
        status: "error",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      navigate("/carrito");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChakraProvider>
      <Box position="relative" minHeight="100vh">
        <VStack spacing={4} p={"12px"} pb="240px">
          <BlackBox
            titulo={`Mi carrito`}
            info="Revisá que estén todos los productos que estás buscando"
            showBackButton={true}
          />
          <Box w="100%" bg="gray.50" p={4} borderRadius="md">
            <VStack align="stretch" spacing={0}>
              {user?.direccion && (
                <>
                  <Text fontWeight="medium">Dirección guardada:</Text>
                  <Text color="gray.700">{user.direccion}</Text>

                  <FormControl display="flex" alignItems="center" marginTop={4}>
                    <Switch
                      id="use-alternative-address"
                      isChecked={useAlternativeAddress}
                      onChange={(e) =>
                        setUseAlternativeAddress(e.target.checked)
                      }
                      colorScheme="red"
                    />
                    <FormLabel htmlFor="use-alternative-address" mb="0" ml={2}>
                      Usar otra dirección para este pedido
                    </FormLabel>
                  </FormControl>
                </>
              )}

              {(useAlternativeAddress || !user?.direccion) && (
                <FormControl isInvalid={!!addressError} isRequired>
                  <FormLabel>
                    {user?.direccion
                      ? "Dirección alternativa"
                      : "Dirección de entrega"}
                  </FormLabel>
                  <Input
                    placeholder="Ingresa la dirección de entrega"
                    value={
                      useAlternativeAddress ? alternativeAddress : direccion
                    }
                    onChange={(e) => {
                      if (useAlternativeAddress) {
                        setAlternativeAddress(e.target.value);
                      } else {
                        setDireccion(e.target.value);
                      }
                      setAddressError("");
                    }}
                    bg="white"
                  />
                  {addressError && (
                    <FormErrorMessage>{addressError}</FormErrorMessage>
                  )}
                  <FormHelperText>
                    Asegúrate de incluir calle, número y referencias útiles
                  </FormHelperText>
                </FormControl>
              )}
            </VStack>
          </Box>
          <Box w="100%" bg="gray.50" p={4} borderRadius="md">
            <FormControl>
              <HStack>
                <Input
                  placeholder="Código de descuento"
                  value={discountCode}
                  bg="white" // Fondo blanco
                  fontWeight="bold"
                  fontFamily="Italic"
                  color="#ca0017" // Color del texto
                  onChange={(e) => {
                    setDiscountCode(e.target.value);
                    setDiscountCodeError("");
                  }}
                  isDisabled={isDiscountApplied}
                  focusBorderColor="red.500" // Opcional: color del borde al enfocar
                />
                {!isDiscountApplied ? (
                  <Button
                    colorScheme="red"
                    onClick={handleApplyDiscountCode}
                    isLoading={isLoading}
                  >
                    Aplicar
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    colorScheme="red"
                    onClick={handleRemoveDiscount}
                  >
                    Quitar
                  </Button>
                )}
              </HStack>
              {discountCodeError && (
                <Text color="red.500" fontSize="sm" mt={2}>
                  {discountCodeError}
                </Text>
              )}
              {isDiscountApplied && (
                <Text color="green.500" fontSize="sm" mt={2}>
                  Descuento aplicado: -${discountAmount.toFixed(2)}
                </Text>
              )}
            </FormControl>
          </Box>
          <VStack w="100%" spacing={0}>
            {pedidoActual?.attributes?.pedido_articulos &&
            pedidoActual.attributes.pedido_articulos.length > 0 ? (
              pedidoActual.attributes.pedido_articulos.map((product, index) => (
                <BagCard
                  key={index}
                  producto={product}
                  onQuantityChange={handleQuantityChange}
                />
              ))
            ) : (
              <Text>El carrito está vacío</Text>
            )}
          </VStack>

          <Textarea
            placeholder="Agrega detalles adicionales (máximo 150 palabras)"
            value={detalle}
            onChange={(e) => setDetalle(e.target.value)}
            maxLength={150}
            resize="vertical"
          />
        </VStack>

        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="white"
          p={4}
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1)"
        >
          <Flex justifyContent="flex-start" alignItems="center" gap=".4rem">
            <Text fontSize="24px" fontWeight="bold">
              Total:
            </Text>
            <Text fontSize="24px" fontWeight="bold">
              $
            </Text>
            <Text fontSize="24px" fontWeight="bold">
              {pedidoActual?.attributes?.total != null
                ? Number(pedidoActual.attributes.total).toFixed(2)
                : "0.00"}
            </Text>
          </Flex>
          <Button
            w="100%"
            borderRadius="24px"
            bgColor="#CA0017"
            color="white"
            fontSize="1rem"
            height="3rem"
            onClick={handleFinalizarPedido}
            isLoading={isLoading}
            loadingText="Procesando pedido..."
            disabled={
              isLoading ||
              !pedidoActual?.attributes?.pedido_articulos ||
              pedidoActual.attributes.pedido_articulos.length === 0 ||
              (useAlternativeAddress
                ? !alternativeAddress.trim()
                : !user?.direccion && !direccion.trim())
            }
            _hover={{
              bgColor: "#A80012",
            }}
            _active={{
              bgColor: "#8C0010",
            }}
            id="finalizarCompraCarrito"
          >
            Finalizar y Contactar por WhatsApp
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
