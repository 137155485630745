import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Text,
  InputRightElement,
  HStack,
  Stack,
  Grid,
  border,
} from "@chakra-ui/react";
import imgBack from "../assets/HeroBack.png";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
  Search2Icon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import CategoryComponent from "./Categoria";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProductCard from "./cardProd";
import Carousel from "../Landing/MasVendidos";
import Carne from "../assets/CatCarne.png";
import Oferta from "../assets/CatOfer.png";
import Pollo from "../assets/CatPollo.png";
import Cerdo from "../assets/CatCerdo.png";
import Achuras from "../assets/CatAchuras.jpg";
import Cordero from "../assets/corderoCat.jpg";
import Vino from "../assets/Vino.jpg";
import Lechuga from "../assets/Lechuga.jpg";
import Leña from "../assets/Leña.jpg";
import Embutidos from "../assets/Embutidos.jpg";

const categoryImages = {
  "Productos en Ofertas": Oferta,
  "Productos De Ternera": Carne,
  "Productos de Pollo": Pollo,
  "Productos de Cerdo": Cerdo,
  "Achuras": Achuras,
  "Cordero": Cordero,
  "Embutidos":Embutidos,
  "Verduras Hidroponica":Lechuga,
  "Vinos": Vino,
  "Leña": Leña,
};

const CategoryButton = ({ name, id }) => {
  const handleClick = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Button
      bg="black"
      color="white"
      borderRadius="full"
      px={6}
      py={2}
      _hover={{ bg: "gray.800" }}
      minWidth="auto"
      whiteSpace="nowrap"
      onClick={handleClick}
      id="redirCategoriasEnCatalogo"
    >
      {name}
    </Button>
  );
};
const CatalogoCompleto = ({ categori }) => {
  const scrollRef = useRef(null);
  const location = useLocation();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  // Obtener estados del Redux store
  const cati = useSelector((state) => state.categories);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const categoris = cati?.map((cat) => ({ id: cat?.id, nombre: cat?.nombre }));
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const scrollContainer = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
      updateArrowVisibility();
    }
  };
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        // Usar setTimeout para asegurar que el desplazamiento ocurra después de que el componente se haya renderizado completamente
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    } else {
      // Si no hay hash, desplazar al inicio de la página
      window.scrollTo(0, 0);
    }
  }, [location, categori]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      // Mostrar todos los elementos si searchTerm está vacío
      if (categori) {
        const allItems = categori?.sub_categorias?.flatMap(
          (subCat) => subCat.articulos
        );
        setFilteredItems(allItems);
      } else {
        const allItems = cati?.flatMap((cat) =>
          cat.sub_categorias?.flatMap((subCat) => subCat.articulos)
        );
        setFilteredItems(allItems);
      }
    } else {
      // Filtrar los elementos si searchTerm tiene algún valor
      if (categori) {
        const filtered = categori?.sub_categorias?.flatMap((subCat) =>
          subCat.articulos.filter((articulo) =>
            Object.values(articulo).some((value) =>
              value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        );
        setFilteredItems(filtered);
      } else {
        const filtered = cati?.flatMap((cat) =>
          cat.sub_categorias?.flatMap((subCat) =>
            subCat.articulos?.filter((articulo) =>
              Object.values(articulo).some((value) =>
                value
                  ?.toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
            )
          )
        );
        setFilteredItems(filtered);
      }
    }
  }, [searchTerm, categori, cati]);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const updateArrowVisibility = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    updateArrowVisibility();
  }, []);

  const renderSearchResults = () => (
    <VStack spacing={4} width="100%">
      <Heading size="lg">Resultados de búsqueda</Heading>
      <Grid gridGap={"12px"}>
        {filteredItems.length > 0 ? (
          filteredItems?.map((item) => (
            <ProductCard key={item?.id} product={item} SubCat={"Ofertas"} />
          ))
        ) : (
          <p style={{ whiteSpace: "nowrap" }}>
            No se encontro ningun articulo con esas caracteristicas
          </p>
        )}
      </Grid>
    </VStack>
  );

  const getCategoryImage = (categoryName) => {
    return categoryImages[categoryName];
  };


  const catalogFull = (
    <VStack spacing={0} width="100%" style={{ marginTop: "80px" }}>
      <Box
        position="relative"
        overflow="hidden"
        borderRadius="24px"
        width="100%"
        height="48"
        backgroundImage={`url(${imgBack})`}
        backgroundSize="cover"
        backgroundPosition="center"
        className="animate__animated  animate__bounceInLeft"
      >
        <Box
          position="absolute"
          inset={0}
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="left"
          padding={"1.5rem"}
        >
          <Box
            color="white"
            fontSize="40px"
            fontWeight="bold"
            textAlign={"left"}
          >
            CATÁLOGO COMPLETO
          </Box>
        </Box>
      </Box>
      <Box position="relative" maxW="100dvw" mt={4} mb={4}>
        <Flex position="relative" alignItems="center">
          {showLeftArrow && (
            <Button
              onClick={() => scrollContainer(-200)}
              position="absolute"
              left="-1rem"
              zIndex={1}
              bg="white"
              _hover={{ bg: "gray.100" }}
              borderRadius="full"
              size="sm"
            >
              <Icon as={ArrowBackIcon} boxSize={4} color="gray.500" />
            </Button>
          )}
          <Flex
            ref={scrollRef}
            overflowX="scroll"
            scrollBehavior="smooth"
            css={{
              "&::WebkitScrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
            gap={4}
            px={4}
          >
            {categoris?.map((category) => (
              <CategoryButton
                key={category.id}
                name={category.nombre}
                id={category.id}
              />
            ))}
          </Flex>
          {showRightArrow && (
            <Button
              onClick={() => scrollContainer(200)}
              position="absolute"
              right="-1rem"
              zIndex={1}
              bg="white"
              _hover={{ bg: "gray.100" }}
              borderRadius="full"
              size="sm"
            >
              <Icon as={ArrowForwardIcon} boxSize={4} color="black" />
            </Button>
          )}
        </Flex>
      </Box>

      <InputGroup style={{ ...styles.inputGroup }} id="searchBar">
        <InputLeftElement pointerEvents="none">
          <Search2Icon />
        </InputLeftElement>
        <Input
          style={styles.input} // Aquí aplicamos el borde al Input
          placeholder="Buscar producto"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          _focus={{
            boxShadow: "inset 0 0 0 1px rgba(0,0,0,1)",
            border: "none",
          }}
          id="searchBar"
        />
        <InputRightElement
          onClick={() => setSearchTerm("")}
          cursor="pointer"
          style={{ pointerEvents: "auto" }} // Aseguramos que el botón sea clickeable
        >
          <SmallCloseIcon />
        </InputRightElement>
      </InputGroup>

      {searchTerm
        ? renderSearchResults()
        : cati?.map((Cat) => (
            <VStack
              key={Cat.id}
              id={Cat.id}
              width="100%"
              alignItems="flex-start"
            >
              <Heading
                color="black"
                fontSize="4xl"
                fontWeight="bold"
                textAlign="left"
                marginLeft=".5rem"
                paddingTop="1rem"
                lineHeight="2rem"
              >
                {(() => {
                  const categoriaNombre = Cat?.nombre || ""; // Asegúrate de que la cadena no sea undefined
                  const words = categoriaNombre.trim().split(/\s+/); // Divide la cadena en palabras
                  const ultimaPalabra = words[words.length - 1]; // Devuelve la última palabra
                  return ultimaPalabra;
                })()}
              </Heading>
              {Cat.sub_categorias?.map((subCat) => (
                <CategoryComponent
                  key={subCat.id}
                  Categoria={subCat}
                  nombreCat={""}
                  id={subCat.id}
                />
              ))}
            </VStack>
          ))}
    </VStack>
  );

  const catalogCategoria = (
    <VStack
      spacing={0}
      width="100%"
      style={{ marginTop: "80px" }}
      alignItems="flex-start"
    >
      <Box position="relative" width="100%" mb={4}>
        <Box
          //  onClick={() => setIsOpen(!isOpen)} 
          position="relative"
          overflow="hidden"
          borderRadius={isOpen ? "24px 24px 0 0" : "24px"}
          width="100%"
          height="110px"
          backgroundImage={`url(${getCategoryImage(categori?.nombre)})`}
          backgroundSize="cover"
          backgroundPosition="center"
          className="animate__animated animate__bounceInLeft"
          cursor="pointer"
        >
          <Box
            position="absolute"
            inset={0}
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="1.5rem"
          >
            <Box
              color="white"
              fontSize="40px"
              fontWeight="bold"
              textAlign="left"
            >
              {(() => {
                const categoriaNombre = categori?.nombre || "";
                const words = categoriaNombre.trim().split(/\s+/);
                const ultimaPalabra = words[words.length - 1];
                return ultimaPalabra.toUpperCase();
              })()}
            </Box>
            {/* <ChevronDownIcon
              color="white"
              boxSize={8}
              transform={isOpen ? "rotate(180deg)" : "none"}
              transition="transform 0.2s"
            /> */}
          </Box>
        </Box>

       {isOpen && (
  <Box
    position="absolute"
    top="100%"
    left={0}
    right={0}
    zIndex={10}
    borderRadius="0 0 24px 24px"
    overflow="hidden"
    backgroundColor="white"
    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
    maxHeight="300px" // Altura máxima
    overflowY="auto" // Scroll si es necesario
  >
    {cati?.filter(cat => cat.id !== categori.id).map((cat) => (
      <Box
        key={cat.id}
        onClick={() => {
          navigate(`/${cat.nombre}`);
          setIsOpen(false);
        }}
        position="relative"
        height="60px" // Altura reducida
        backgroundImage={`url(${getCategoryImage(cat.nombre)})`}
        backgroundSize="cover"
        backgroundPosition="center"
        cursor="pointer"
      >
        <Box
          position="absolute"
          inset={0}
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          padding="1rem" // Padding reducido
          _hover={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          <Box color="white" fontSize="24px" fontWeight="bold">
            {cat.nombre.split(/\s+/).pop().toUpperCase()}
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
)}
      </Box>

      <InputGroup style={{ ...styles.inputGroup }}>
        <InputLeftElement pointerEvents="none">
          <Search2Icon />
        </InputLeftElement>
        <Input
          style={styles.input}
          placeholder="Buscar producto"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          _focus={{
            boxShadow: "inset 0 0 0 1px rgba(0,0,0,1)",
            border: "none",
          }}
        />
        <InputRightElement
          onClick={() => setSearchTerm("")}
          cursor="pointer"
          style={{ pointerEvents: "auto" }}
        >
          <SmallCloseIcon />
        </InputRightElement>
      </InputGroup>

      {searchTerm
        ? renderSearchResults()
        : categori?.sub_categorias?.map((subCat) => (
            <CategoryComponent
              key={subCat.id}
              Categoria={subCat}
              nombreCat={categori?.nombre}
            />
          ))}
      {/* <Carousel /> */}
    </VStack>
  );

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      p="12px"
      width="100%"
      paddingBottom={"80px"}
    >
      {categori ? catalogCategoria : catalogFull}
    </Flex>
  );
};

const styles = {
  inputGroup: {
    transition: "all 0.2s ease-in-out",
    boxShadow: "none",
    bgColor: "#F2F2F2",
    marginBottom: ".25rem",
    backgroundColor: "#F2F2F2",
    width: "100%",
    borderRadius: "24px",
  },
  input: {
    color: "black",
    borderRadius: "24px",
  },
};

export default CatalogoCompleto;
