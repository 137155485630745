import React, { useState, useEffect } from 'react';
import { Box, Link } from '@chakra-ui/react';

const WhatsAppButton = ({ phoneNumber }) => {
  const [isIOS, setIsIOS] = useState(false);
  
  useEffect(() => {
    // Mejor detección de iOS
    const detectIOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    };
    
    setIsIOS(detectIOS());
  }, []);

  const formatPhoneNumber = (phone) => {
    // Remove any non-numeric characters
    const cleanNumber = phone.replace(/\D/g, '');
    // Add country code if not present
    return cleanNumber.startsWith('549') ? cleanNumber : `549${cleanNumber}`;
  };

  const handleClick = (e) => {
    e.preventDefault();
    const formattedNumber = formatPhoneNumber(phoneNumber);
    
    // Crear URLs para diferentes plataformas
    const directUrl = isIOS
      ? `whatsapp://send?phone=${formattedNumber}`
      : `https://wa.me/${formattedNumber}`;
    
    const webUrl = `https://web.whatsapp.com/send?phone=${formattedNumber}`;
    
    // Mejorar la lógica de apertura para iOS
    if (isIOS) {
      // Para iOS, intentamos primero la URL directa
      window.location.href = directUrl;
      
      // Si después de 1500ms seguimos en la misma página, probablemente falló
      const fallbackTimer = setTimeout(() => {
        if (document.hasFocus()) {
          // Intentar la versión web como respaldo en iOS
          window.location.href = `https://api.whatsapp.com/send?phone=${formattedNumber}`;
        }
      }, 1500);
      
      return;
    }
    
    // Para Android y otros dispositivos
    window.open(directUrl, '_blank');
    
    // Fallback para desktop
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setTimeout(() => {
        window.open(webUrl, '_blank');
      }, 500);
    }
  };

  return (
    <Box
      position="fixed"
      bottom="100px"
      right="20px"
      zIndex={1000}
      className="whatsapp-button"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="60px"
      height="60px"
      backgroundColor="#25D366"
      borderRadius="50%"
      boxShadow="0 4px 10px rgba(0,0,0,0.3)"
      _hover={{
        transform: "scale(1.1)",
        transition: "all 0.3s ease-in-out"
      }}
    >
      <Link
        href="#"
        onClick={handleClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="48px"
        height="48px"
        aria-label="Contactar por WhatsApp"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.216 175.552" width="60">
          <path fill="#fff" fillRule="evenodd" d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"/>
        </svg>
      </Link>
    </Box>
  );
};

export default WhatsAppButton;