import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";


const API_BASE = process.env.REACT_APP_API_BASE;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const initialState = {
  user: null,
  token: null, // Asegúrate de que el token se guarde aquí
  role: null,
  categories: [],
  articulos: [],
  valores: [],
  cart: [],
  pedidos: [],
  pedidoActual: {
    id: null,
    attributes: {
      pedido_articulos: [],
      total: 0,
    },
  },
  currentOrder: null,
  orders: [],
  historial: [],
  cartTotal: 0,
  status: "idle",
  error: null,
  pedidoFinalizado: false,
  privacyAccepted: false,
};
// Async thunks
export const fetchCategories = createAsyncThunk(
  "counter/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/categorias?populate=img&populate=sub_categorias.articulos.valors&populate=sub_categorias.articulos.img&populate=sub_categorias.articulos.insignias`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPedidosUser = createAsyncThunk(
  "counter/fetchPedidos",
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/pedidos-with-user?userId=${userId}`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchValor = createAsyncThunk(
  "counter/fetchValor",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE}/api/valors`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_BASE}/api/auth/local/register`,
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      // Enhanced error handling
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return thunkAPI.rejectWithValue({
          message: error.response.data.error.message,
          status: error.response.status,
          data: error.response.data
        });
      } else if (error.request) {
        // The request was made but no response was received
        return thunkAPI.rejectWithValue({
          message: "No se pudo conectar con el servidor",
          status: 0
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return thunkAPI.rejectWithValue({
          message: error.message,
          status: 0
        });
      }
    }
  }
);

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.withCredentials = true; // Asegurar que todas las peticiones incluyan credenciales
    localStorage.setItem("token", token);
  } else {
    delete axios.defaults.headers.common["Authorization"];
    axios.defaults.withCredentials = false;
    localStorage.removeItem("token");
  }
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (credentials, thunkAPI) => {
    try {
      const loginResponse = await axios.post(`${API_BASE}/api/auth/local`, {
        identifier: credentials.email,
        password: credentials.password,
      });

      const { jwt: token, user } = loginResponse.data;
      setAuthToken(token);

      const userResponse = await axios.get(
        `${API_BASE}/api/users/me?populate=role&populate=pedidos`
      );

      const userData = userResponse.data;
      const role = userData.role ? userData.role.name : null;
      const pedidos = userData.pedidos || [];

      return {
        ...userData,
        token,
        role,
        pedidos,
      };
    } catch (error) {
      console.error("Login error:", error.response?.data || error.message);
      return thunkAPI.rejectWithValue(
        error.response?.data?.error?.message || error.message
      );
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateProfile",
  async ({ userId, userData }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.put(
        `${API_BASE}/api/users/${userId}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "user/confirmEmail",
  async (confirmationToken, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_BASE}/api/auth/email-confirmation`,
        {
          params: { confirmation: confirmationToken },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk("user/logout", async () => {
  setAuthToken(null);
  return null;
});

export const handleGoogleCallback = createAsyncThunk(
  "user/handleGoogleCallback",
  async ({ code, acceptPrivacy }, thunkAPI) => {
    try {
      console.log('Enviando código a backend:', code);
      
      const authResponse = await axios.post(
        `${API_BASE}/strapi-google-auth/user-profile`,
        { code },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/plain, */*',
          },
          withCredentials: true // Esto configura credentials: 'include'
        }
      );

      console.log('Respuesta del servidor:', authResponse.data);

      if (!authResponse.data?.data?.token) {
        throw new Error("No se recibió un token JWT válido del servidor");
      }

      const { token, user } = authResponse.data.data;

      if (acceptPrivacy) {
        await axios.put(
          `${API_BASE}/api/users/${user.id}`,
          { poli_priv: true },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            withCredentials: true
          }
        );
        user.poli_priv = true;
      }

      // Configurar axios para futuras peticiones
      setAuthToken(token);

      return {
        user,
        token,
      };
    } catch (error) {
      console.error('Error completo en handleGoogleCallback:', error);
      
      // Mejorar el mensaje de error
      let errorMessage = 'Error en la autenticación con Google';
      if (error.response?.data?.error?.message) {
        errorMessage = error.response.data.error.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const crearPedido = createAsyncThunk(
  "counter/crearPedido",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedidos`,
        {
          data: {
            user: { id: user.id },
            comercio: { id: 1 },
            estado: "xxxx",
            total: 0,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const crearPedidoCompleto = createAsyncThunk(
  "counter/crearPedidoCompleto",
  async (pedidoData, thunkAPI) => {
    const { token, discount, articulos, valores } = thunkAPI.getState();
    try {
      // Enriquecer los datos de artículos con información de unidad de medida y GrsPorcion
      const articulosEnriquecidos = pedidoData.articulos.map(item => {
        const articulo = articulos.find(a => a.id === item.articuloId);
        const valor = valores.find(v => v.id === item.valorId);
        
        return {
          ...item,
          medida: articulo?.Medida || '/Kg', // Valor por defecto
          grsPorcion: valor?.attributes?.GrsPorcion || 0
        };
      });

      const response = await axios.post(
        `${API_BASE}/api/pedidos/crear-completo`,
        {
          data: {
            ...pedidoData,
            articulos: articulosEnriquecidos,
            codigos_de_descuentos: discount?.codeId ? [discount.codeId] : [],
            code: discount?.codeId ? discount.code : null,
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (discount?.codeId) {
        await axios.put(
          `${API_BASE}/api/discount-codes/${discount.codeId}/increment-usage`,
          { data: { currentUses: { increment: 1 } } }
        );
      }
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const syncCartWithServer = createAsyncThunk(
  "counter/syncCartWithServer",
  async (_, thunkAPI) => {
    const { pedidoActual, token } = thunkAPI.getState();
    if (!pedidoActual || !pedidoActual.id) return;

    try {
      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoActual.id}`,
        {
          data: {
            pedido_articulos: pedidoActual.attributes.pedido_articulos,
            total: pedidoActual.attributes.total,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const agregarArticuloPedido = createAsyncThunk(
  "counter/agregarArticuloPedido",
  async (
    { pedidoId, articuloId, valorId, cantidad, precio, nombre, valor },
    thunkAPI
  ) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedido-articulos`,
        {
          data: {
            pedido: pedidoId,
            articulo: articuloId,
            valor: valorId,
            cantidad: Number(cantidad),
            precio_unitario: Number(precio),
            subtotal: Number(cantidad) * Number(precio),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        id: response.data.data.id,
        articleId: articuloId,
        name: nombre,
        price: Number(precio),
        quantity: Number(cantidad),
        valor: valor,
        valorId: valorId,
        precioFinal: Number(precio),
        subtotal: Number(cantidad) * Number(precio),
        ...response.data.data.attributes,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const finalizarPedido = createAsyncThunk(
  "counter/finalizarPedido",
  async ({ pedidoId }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "pendiente",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const pedidoPendiente = createAsyncThunk(
  "counter/pedidoPendiente",
  async ({ pedidoId, detalle }, thunkAPI) => {
    const { token } = thunkAPI.getState();
    try {
      // Solo permitir la transición de XXXX a pendiente
      const pedidoResponse = await axios.get(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (pedidoResponse.data.data.attributes.estado !== "xxxx") {
        throw new Error(
          "El pedido debe estar en estado XXXX para pasar a pendiente"
        );
      }

      const response = await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "pendiente",
            detalle: detalle,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const ProcesoPedidoAdmin = createAsyncThunk(
  "counter/procesoPedidoAdmin",
  async (pedidoId, thunkAPI) => {
    const { token } = thunkAPI.getState();

    try {
      // Solo permitir la transición de pendiente a en_proceso
      const pedidoResponse = await axios.get(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (pedidoResponse.data.data.attributes.estado !== "pendiente") {
        throw new Error(
          "El pedido debe estar en estado pendiente para procesarlo"
        );
      }

      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "en_proceso",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refetch all orders after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const PedidoCompletAdmin = createAsyncThunk(
  "counter/pedidoCompletAdmin",
  async (pedidoId, thunkAPI) => {
    const { token, pedidoActual } = thunkAPI.getState();

    try {
      // Solo permitir la transición de en_proceso a finalizado
      const pedidoResponse = await axios.get(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (pedidoResponse.data.data.attributes.estado !== "en_proceso") {
        throw new Error("El pedido debe estar en proceso para finalizarlo");
      }

      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}`,
        {
          data: {
            estado: "finalizado",
            total: pedidoActual?.attributes?.total,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refetch the order history after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// src/helpers/whatsappHelpers.js

export const createWhatsAppMessage = (pedidoCreado) => {
  try {
    // Validación básica
    if (!pedidoCreado || !pedidoCreado.attributes) {
      throw new Error("Datos del pedido inválidos");
    }

    let message = `¡Hola! He realizado un nuevo pedido: Número #${pedidoCreado.id}\n\n`;

    // Validar que existan los artículos
    const pedidoArticulos =
      pedidoCreado.attributes.pedido_articulos?.data || [];

    pedidoArticulos.forEach((product, index) => {
      const articulo = product.attributes?.articulo?.data?.attributes;
      const valor = product.attributes?.valor?.data?.attributes;

      if (!articulo || !valor) return; // Skip si faltan datos

      message += `${index + 1}. ${articulo.nombre} - ${valor.nombre}\n`;
      message += `   Cantidad: ${product.attributes.cantidad}\n`;
      message += `   Precio unitario: $${product.attributes.precio_unitario}\n`;
      message += `   Subtotal: $${product.attributes.subtotal}\n\n`;
    });

    // Total y número de pedido
    message += `\nTotal del pedido: $${pedidoCreado.attributes.total || 0}`;
    message += `\nNúmero de pedido: #${pedidoCreado.id}`;

    // Detalles adicionales si existen
    if (pedidoCreado.attributes.detalle) {
      message += `\n\nDetalles adicionales: ${pedidoCreado.attributes.detalle}`;
    }

    return encodeURIComponent(message);
  } catch (error) {
    console.error("Error creando mensaje de WhatsApp:", error);
    // Retornar un mensaje básico en caso de error
    return encodeURIComponent(
      `¡Hola! He realizado un nuevo pedido #${pedidoCreado?.id || "N/A"}`
    );
  }
};

export const generateWhatsAppUrl = (message, phoneNumber = "2914228046") => {
  try {
    // Asegurar que el número de teléfono tenga el formato correcto
    const formattedPhone = phoneNumber.startsWith("549")
      ? phoneNumber
      : `549${phoneNumber}`;

    // Detectar el dispositivo
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // URLs específicas por plataforma
    if (/android/i.test(userAgent)) {
      return `intent://send?phone=${formattedPhone}&text=${message}#Intent;scheme=whatsapp;package=com.whatsapp;end`;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return `whatsapp://send?phone=${formattedPhone}&text=${message}`;
    } else {
      return `https://api.whatsapp.com/send?phone=${formattedPhone}&text=${message}`;
    }
  } catch (error) {
    console.error("Error generando URL de WhatsApp:", error);
    // URL de fallback que debería funcionar en la mayoría de los casos
    return `https://api.whatsapp.com/send?phone=549${phoneNumber}&text=${message}`;
  }
};

export const formatPhoneNumber = (phone) => {
  try {
    const cleaned = phone.replace(/\D/g, "");
    return cleaned.startsWith("549") ? cleaned : `549${cleaned}`;
  } catch (error) {
    console.error("Error formateando número de teléfono:", error);
    return phone; // Retornar el número original si hay error
  }
};

export const PedidoCanceladoAdmin = createAsyncThunk(
  "counter/pedidoCanceladoAdmin",
  async (pedidoId, thunkAPI) => {
    const { token } = thunkAPI.getState();

    try {
      // Cambiamos la URL para que coincida con la nueva ruta en el backend
      await axios.put(
        `${API_BASE}/api/pedidos/${pedidoId}/estado`,
        {
          estado: "cancelado", // Simplificamos el body, ya no necesitamos el wrapper "data"
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Refetch all orders after updating the order status
      await thunkAPI.dispatch(obtenerTodosPedidos());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const obtenerHistorialPedidos = createAsyncThunk(
  "counter/obtenerHistorial",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    try {
      let params = {
        populate: "pedido_articulos.articulo,pedido_articulos.valor,user",
      };

      // Si el usuario no es admin, filtrar por su ID
      if (user?.role !== "Admin") {
        params["filters[user][id][$eq]"] = user?.id;
      }

      const response = await axios.get(`${API_BASE}/api/pedidos`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data;
    } catch (error) {
      console.error(
        "Error en obtenerHistorialPedidos:",
        error.response?.data || error.message
      );
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const obtenerTodosPedidos = createAsyncThunk(
  "counter/obtenerTodosPedidos",
  async (_, thunkAPI) => {
    const { user, token } = thunkAPI.getState();
    let allPedidos = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
      do {
        let params = {
          populate: "pedido_articulos.articulo,pedido_articulos.valor,user",
          "pagination[page]": currentPage,
          "pagination[pageSize]": 100, // Aumentamos el tamaño de página para reducir el número de solicitudes
        };

        // Si el usuario no es admin, filtrar por su ID
        if (user?.role !== "Admin") {
          params["filters[user][id][$eq]"] = user?.id;
        }

        const response = await axios.get(`${API_BASE}/api/pedidos`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data, meta } = response.data;
        allPedidos = [...allPedidos, ...data];
        totalPages = meta.pagination.pageCount;
        currentPage++;
      } while (currentPage <= totalPages);

      console.log("Total pedidos obtenidos:", allPedidos.length);
      return allPedidos;
    } catch (error) {
      console.error("Error fetching pedidos:", error);
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const calculateCartTotal = (pedido_articulos) => {
  return pedido_articulos.reduce(
    (total, item) => total + Number(item.subtotal || 0),
    0
  );
};

const calculatePedidoTotal = (pedidoArticulos) => {
  return pedidoArticulos.reduce(
    (total, articulo) => total + Number(articulo.subtotal),
    0
  );
};

export const clearCart = createAction("counter/clearCart");

export const agregarAlCarrito = createAsyncThunk(
  "counter/agregarAlCarrito",
  async (
    { pedidoId, articuloId, valorId, cantidad, precio, nombre, valor },
    thunkAPI
  ) => {
    const { token } = thunkAPI.getState();
    try {
      const response = await axios.post(
        `${API_BASE}/api/pedido-articulos`,
        {
          data: {
            pedido: pedidoId,
            articulo: articuloId,
            valor: valorId,
            cantidad: Number(cantidad),
            precio_unitario: Number(precio),
            subtotal: Number(cantidad) * Number(precio),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        id: response.data.data.id,
        articleId: articuloId,
        name: nombre,
        price: Number(precio),
        quantity: Number(cantidad),
        valor: valor,
        valorId: valorId,
        precioFinal: Number(precio),
        subtotal: Number(cantidad) * Number(precio),
        ...response.data.data.attributes,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);




// export const updatePrivacyAcceptance = createAsyncThunk(
//   "user/updatePrivacyAcceptance",
//   async ({ userId, accepted }, thunkAPI) => {
//     try {
//       const response = await axios.put(
//         `${API_BASE}/api/users/${userId}`,
//         { poli_priv: accepted },
//         {
//           headers: {
//             Authorization: `Bearer ${thunkAPI.getState().token}`,
//             'Content-Type': 'application/json',
//           },
//           withCredentials: true
//         }
//       );
      
//       return response.data;
//     } catch (error) {
//       console.error('Error updating privacy acceptance:', error);
//       return thunkAPI.rejectWithValue(
//         error.response?.data?.error?.message || 'Error updating privacy settings'
//       );
//     }
//   }
// );

export const updatePrivacyAcceptance = createAsyncThunk(
  "user/updatePrivacyAcceptance",
  async ({ userId, accepted }, thunkAPI) => {
    try {
      // Imprimir el token para debug
      console.log('Token:', thunkAPI.getState().token);
      
      const response = await axios.put(
        `${API_BASE}/api/users/${userId}`,
        {
          // Usar poli_priv en lugar de poli_priv para coincidir con la columna en la base de datos
          poli_priv: accepted
        },
        {
          headers: {
            Authorization: `Bearer ${thunkAPI.getState().token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      );

      // Agregar log para ver la respuesta
      console.log('Response:', response.data);
      
      return {
        ...response.data,
        // Mantener la consistencia con el estado local
        poli_priv: response.data.poli_priv
      };
    } catch (error) {
      // Mejorar el logging de errores
      console.error('Error completo:', error);
      console.error('Error response:', error.response?.data);
      
      if (error.response?.status === 500) {
        return thunkAPI.rejectWithValue('Error interno del servidor al actualizar la configuración de privacidad');
      }
      
      return thunkAPI.rejectWithValue(
        error.response?.data?.error?.message || 
        error.response?.data?.message || 
        'Error al actualizar la configuración de privacidad'
      );
    }
  }
);

// In your existing slice file
export const applyDiscount = createAction('APPLY_DISCOUNT', (payload) => ({
  payload: {
    ...payload,
    timestamp: Date.now()
  }
}));
export const removeDiscount = createAction('REMOVE_DISCOUNT');

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      if (!state.pedidoActual) {
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      }

      const { articleId, name, price, quantity, valor, valorId, precioFinal } =
        action.payload;

      // Asegurarse que todos los valores numéricos sean números válidos
      const safeQuantity = Number(quantity) || 1;
      const safePrecioFinal = Number(precioFinal) || 0;
      const safePrice = Number(price) || safePrecioFinal;
      const safeSubtotal = safeQuantity * safePrecioFinal;

      const existingItemIndex =
        state.pedidoActual.attributes.pedido_articulos.findIndex(
          (item) => item.articleId === articleId && item.valorId === valorId
        );

      if (existingItemIndex !== -1) {
        // Actualizar item existente
        const item =
          state.pedidoActual.attributes.pedido_articulos[existingItemIndex];
        item.quantity = safeQuantity;
        item.price = safePrice;
        item.precioFinal = safePrecioFinal;
        item.subtotal = safeSubtotal;
      } else {
        // Agregar nuevo item
        state.pedidoActual.attributes.pedido_articulos.push({
          articleId,
          name,
          price: safePrice,
          quantity: safeQuantity,
          valor,
          valorId,
          precioFinal: safePrecioFinal,
          subtotal: safeSubtotal,
        });
      }

      // Recalcular el total del carrito
      state.pedidoActual.attributes.total =
        state.pedidoActual.attributes.pedido_articulos.reduce(
          (total, item) => total + Number(item.subtotal || 0),
          0
        );
    },
    updateCartQuantity: (state, action) => {
      const { articleId, valorId, quantity } = action.payload;
      const itemIndex =
        state.pedidoActual.attributes.pedido_articulos.findIndex(
          (item) => item.articleId === articleId && item.valorId === valorId
        );

      if (itemIndex !== -1) {
        const item = state.pedidoActual.attributes.pedido_articulos[itemIndex];
        if (Number(quantity) === 0 || quantity === null) {
          // Eliminar el artículo si la cantidad es 0 o null
          state.pedidoActual.attributes.pedido_articulos.splice(itemIndex, 1);
        } else {
          item.quantity = Number(quantity);
          item.subtotal = Number(item.quantity) * Number(item.precioFinal);
          item.updatedAt = new Date().toISOString();
        }
        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
      }
    },

    removeFromCart: (state, action) => {
      const { articleId, valorId } = action.payload;
      if (state.pedidoActual) {
        state.pedidoActual.attributes.pedido_articulos =
          state.pedidoActual.attributes.pedido_articulos.filter(
            (item) =>
              !(item.articleId === articleId && item.valorId === valorId)
          );
        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
      }
    },

    addLocalOrder: (state, action) => {
      state.currentOrder = action.payload;
      state.orders.push(action.payload);
    },
    syncOrderWithServer: (state, action) => {
      const { tempId, realOrder } = action.payload;
      state.orders = state.orders.map((order) =>
        order.id === tempId ? { ...order, ...realOrder } : order
      );
      if (state.currentOrder && state.currentOrder.id === tempId) {
        state.currentOrder = { ...state.currentOrder, ...realOrder };
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(applyDiscount, (state, action) => {
      const { discountCode, codeId, discountPercentage, discountAmount, newTotal } = action.payload;
      
      state.discount = {
        code: discountCode,
        codeId,
        percentage: discountPercentage,
        amount: discountAmount
      };
      
      if (!state.originalTotal) {
        state.originalTotal = state.pedidoActual.attributes.total;
      }
      
      state.pedidoActual.attributes.total = newTotal;
    })
    .addCase(removeDiscount, (state) => {
      // Revert to original total
      if (state.originalTotal) {
        state.pedidoActual.attributes.total = state.originalTotal;
        delete state.pedidoActual.attributes.discountCode;
        delete state.originalTotal;
      }
    })
    .addCase(updatePrivacyAcceptance.pending, (state) => {
      state.status = "loading";
    })
    .addCase(updatePrivacyAcceptance.fulfilled, (state, action) => {
      state.status = "succeeded";
      if (state.user) {
        state.user.poli_priv = action.payload.poli_priv;
      }
      state.privacyAccepted = action.payload.poli_priv;
    })
    .addCase(updatePrivacyAcceptance.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    })
      .addCase(crearPedidoCompleto.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(crearPedidoCompleto.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pedidoActual = action.payload;
        state.cart = [];
        state.cartTotal = 0;
      })
      .addCase(crearPedidoCompleto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(pedidoPendiente.fulfilled, (state, action) => {
        if (action.payload) {
          state.pedidoActual = action.payload;
          state.pedidoFinalizado = true;
        }
      })
      .addCase(pedidoPendiente.rejected, (state, action) => {
        state.error = action.payload;
        state.pedidoFinalizado = false;
      })
      .addCase(agregarAlCarrito.fulfilled, (state, action) => {
        console.log('Payload recibido en agregarAlCarrito:', action?.payload);
        if (!action.payload || !action.payload.data) {
          console.error('Payload inválido en agregarAlCarrito:', action?.payload);
          return state;
        }
    
        // Inicializar pedidoActual si no existe
        if (!state.pedidoActual) {
          state.pedidoActual = {
            id: null,
            attributes: {
              pedido_articulos: [],
              total: 0,
            },
          };
        }
    
        // Extraer datos del payload con validaciones seguras
        const newItem = {
          articleId: action.payload.data.articleId || null,
          valorId: action.payload.data.valorId || null,
          quantity: action.payload.data.quantity || 1, // Default 1 si no existe
          precioFinal: action.payload.data.precioFinal || 0,
          articulo: action.payload.data.articulo || null,
          valor: action.payload.data.valor || null,
        };
    
        // Validar que los datos mínimos necesarios estén presentes
        if (!newItem.articleId || !newItem.valorId) {
          console.error('Datos de artículo incompletos:', action.payload);
          return state;
        }
    
        // Buscar si el artículo ya existe en el carrito
        const existingItemIndex = state?.pedidoActual?.attributes?.pedido_articulos?.findIndex(
          (item) =>
            item.articleId === newItem.articleId &&
            item.valorId === newItem.valorId
        );
    
        if (existingItemIndex !== -1) {
          // Actualizar artículo existente
          state.pedidoActual.attributes.pedido_articulos[existingItemIndex] = {
            ...state.pedidoActual.attributes.pedido_articulos[existingItemIndex],
            ...newItem
          };
        } else {
          // Agregar nuevo artículo
          state.pedidoActual.attributes.pedido_articulos.push(newItem);
        }
    
        // Calcular total del carrito
        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
    })    
      .addCase(clearCart, (state) => {
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
        state.cart = [];
        state.cartTotal = 0;
      })
      .addCase(obtenerTodosPedidos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(obtenerTodosPedidos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.historial = action.payload;
      })
      .addCase(obtenerTodosPedidos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(obtenerHistorialPedidos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(obtenerHistorialPedidos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.historial = action.payload;
      })
      .addCase(obtenerHistorialPedidos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.status = "loading";
        state.confirmationStatus = null;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.confirmationStatus = "success";
        // Opcionalmente, puedes actualizar el estado del usuario aquí si el backend lo devuelve
        // state.user = action.payload.user;
        // state.token = action.payload.jwt;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.status = "failed";
        state.confirmationStatus = "failed";
        state.error = action.payload;
      })
      .addCase(handleGoogleCallback.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.role = action.payload.user.role?.name;

        // Inicializar pedidoActual
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload.map((category) => ({
          id: category.id,
          ...category.attributes,
          sub_categorias: category.attributes.sub_categorias.data.map(
            (subCategory) => ({
              id: subCategory.id,
              ...subCategory.attributes,
              articulos: subCategory.attributes.articulos.data.map(
                (articulo) => ({
                  id: articulo.id,
                  ...articulo.attributes,
                })
              ),
            })
          ),
        }));

        // Extraer todos los artículos de todas las categorías y subcategorías
        state.articulos = state.categories.flatMap((category) =>
          category.sub_categorias.flatMap(
            (subCategory) => subCategory.articulos
          )
        );

        console.log("Artículos cargados:", state.articulos);
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error loading categories:", action.payload);
      })
      .addCase(syncCartWithServer.fulfilled, (state, action) => {
        // Optionally update state if server response differs from local state
        if (action.payload) {
          state.pedidoActual = action.payload;
        }
      })
      .addCase(syncCartWithServer.rejected, (state, action) => {
        // Handle sync errors, maybe revert optimistic updates or show an error message
        state.error = action.payload;
      })
      // Fetch Pedidos User
      .addCase(fetchPedidosUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPedidosUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pedidos = action.payload;
      })
      .addCase(fetchPedidosUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Valor
      .addCase(fetchValor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchValor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.valores = action.payload;
        console.log("Valores loaded:", state.valores);
      })
      .addCase(fetchValor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        console.error("Error loading valores:", action.payload);
      })
      // En el extraReducer para registerUser
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
        state.error = null; // Limpiar errores anteriores
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.status = "succeeded";
        // Podríamos agregar un mensaje de éxito específico si lo necesitas
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        // Mejorar el manejo de errores para incluir errores específicos de validación
        const error = action.payload;
        if (error?.message?.includes("poli_priv")) {
          state.error =
            "Debes aceptar las políticas de privacidad para registrarte";
        } else {
          state.error = error?.message || "Error durante el registro";
        }
      })
      // Login User
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.token = action.payload.token;
        state.role = action.payload.role;

        // Inicializar pedidoActual
        state.pedidoActual = {
          id: null,
          attributes: {
            pedido_articulos: [],
            total: 0,
          },
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "An unknown error occurred";
      })
      // Logout User
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "idle";
        state.user = null;
        state.token = null;
        state.role = null;
        state.cart = [];
        state.cartTotal = 0;
        state.pedidoActual = [];
        state.historial = [];
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Crear Pedido
      .addCase(crearPedido.fulfilled, (state, action) => {
        state.pedidoActual = action.payload;
        state.pedidoActual.attributes.total = 0;
        state.pedidoActual.attributes.pedido_articulos = [];
      })
      .addCase(agregarArticuloPedido.fulfilled, (state, action) => {
        if (!state.pedidoActual) {
          state.pedidoActual = {
            id: action.payload.pedido,
            attributes: {
              pedido_articulos: [],
              total: 0,
            },
          };
        }

        const existingItemIndex =
          state.pedidoActual.attributes.pedido_articulos.findIndex(
            (item) =>
              item.articleId === action.payload.articleId &&
              item.valorId === action.payload.valorId
          );

        if (existingItemIndex !== -1) {
          state.pedidoActual.attributes.pedido_articulos[existingItemIndex] =
            action.payload;
        } else {
          state.pedidoActual.attributes.pedido_articulos.push(action.payload);
        }

        state.pedidoActual.attributes.total = calculateCartTotal(
          state.pedidoActual.attributes.pedido_articulos
        );
      })
      // Finalizar Pedido

      .addCase(finalizarPedido.pending, (state) => {
        state.pedidoFinalizado = false;
      })
      .addCase(finalizarPedido.fulfilled, (state, action) => {
        state.pedidoFinalizado = true;
        // Actualizar otros estados si es necesario
      })
      .addCase(finalizarPedido.rejected, (state, action) => {
        state.pedidoFinalizado = false;
        state.error = action.payload;
      })
      // Generic error handler
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload || action.error.message;
        }
      );
  },
});

export const {
  addToCart,
  updateCartQuantity,
  removeFromCart,
  addLocalOrder,
  syncOrderWithServer,
} = counterSlice.actions;

export default counterSlice.reducer;
