import React from "react";
import bgHero from "../assets/HeroBack.png";
import { Button, Text, Box, useMediaQuery } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import "./Landing.css";
import "animate.css";
import CarouselHero from "./CarrouselHero.jsx"; // Import the new Carousel component
import HeroDesc from "../assets/HeroDesc.jpg"
import img2 from "../assets/HeroImg2.jpg";
import img3 from "../assets/HeroImg3.jpg";
import img4 from "../assets/HeroImg4.jpg";

const Hero = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  // Add more images to the carousel as needed
  const carouselImages = [
    HeroDesc,
    img2,
    img3,
    img4,
    // Add more image URLs here
  ];

  return (
    <div className="contHero">
      <div className="animate__animated animate__bounceInLeft meat-quality-card">
        {isLargerThan768 ? (
          <CarouselHero images={carouselImages} />
        ) : (
          <img
            src={bgHero}
            alt="High quality meat cuts"
            className="meat-image"
          />
        )}
        <div className="overlayHero">
          <div>
            <h2 className="titleHero">CARNES DE CALIDAD</h2>
            <Text className="textRelleno" textAlign="left">
              Productos de calidad premium al costo mayorista ¿Qué estás
              esperando para hacer tu compra?
            </Text>
          </div>
          <Button
            as={NavLink}
            to="/CatFull"
            id="catalogoFull"
            width="100%"
            height="44px"
            padding="10px"
            backgroundColor="#ca0017"
            color="white"
            border="none"
            borderRadius="24px"
            fontSize="20px"
            fontWeight="600"
            cursor="pointer"
            transition="background-color 0.3s ease"
            fontFamily="Segoe UI, Tahoma, Geneva, Verdana, sans-serif"
            _hover={{
              backgroundColor: "#a40013",
            }}
          >
            Consultar catalogo
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;

