import { Box, Button, Flex, HStack, Text, VStack, Skeleton, Stack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { removeFromCart, updateCartQuantity } from "../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";
import imgArt from "../assets/articulos.png";

const LoadingBagCard = () => (
  <HStack w="100%" p={4} borderBottom="solid 1px lightgrey" spacing={4}>
    <Skeleton height="68px" width="68px" />
    <Stack flex={1} spacing={2}>
      <Skeleton height="24px" width="80px" />
      <Skeleton height="20px" width="140px" />
      <Skeleton height="20px" width="100px" />
    </Stack>
    <Skeleton height="44px" width="150px" borderRadius="full" />
  </HStack>
);

const BagCard = ({ producto }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const articulos = useSelector(state => state?.articulos ?? []);
  const valores = useSelector(state => state?.valores ?? []);

  const articleId = producto?.articleId || producto?.articulo?.id;
  const valorId = producto?.valorId || producto?.valor?.id;
  const quantity = producto?.quantity || producto?.stock || 0;
  const [stock, setStock] = useState(quantity);

  useEffect(() => {
    setStock(quantity);
  }, [quantity]);

  useEffect(() => {
    if (articulos.length > 0 && valores.length > 0) {
      setIsLoading(false);
    }
  }, [articulos, valores]);

  if (isLoading) return <LoadingBagCard />;

  if (!articleId || !valorId || !Array.isArray(articulos) || !Array.isArray(valores)) {
    console.warn('Datos inválidos en BagCard:', {
      producto,
      tieneArticulos: Array.isArray(articulos),
      tieneValores: Array.isArray(valores)
    });
    return null;
  }

  const Descuento = articulos.find(art => art?.id === Number(articleId));
  const medida = valores.find(val => val?.id === Number(valorId));

  if (!Descuento || !medida) {
    console.warn('Artículo o valor no encontrado:', { articleId, valorId });
    return null;
  }

  const artDesct = Descuento.descPorciento ?? 0;
  const valorNombre = medida.attributes?.nombre ?? 'Sin nombre';
  // Split the valorNombre at the hyphen and take only the first part
  const formattedValorNombre = valorNombre.split('-')[0];

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart({ articleId, valorId }));
    setStock(0);
  };

  const incrementarStock = () => {
    const newQuantity = Number(stock) + 1;
    setStock(newQuantity);
    dispatch(updateCartQuantity({ articleId, valorId, quantity: newQuantity }));
  };

  const decrementarStock = () => {
    if (Number(stock) > 1) {
      const newQuantity = Number(stock) - 1;
      setStock(newQuantity);
      dispatch(updateCartQuantity({ articleId, valorId, quantity: newQuantity }));
    } else if (Number(stock) === 1) {
      handleRemoveFromCart();
    }
  };
  const articuloMedida = Descuento.Medida || '/Kg'; // Obtener la medida del artículo
  const grsPorcion = medida.attributes?.GrsPorcion || 0; // Obtener gramos por porción
  return (
    <HStack w="100%" p={0} borderBottom="solid 1px lightgrey" spacing={0}>
      <Flex flex={1} justifyContent="center" alignItems="center">
        <img src={imgArt} alt={Descuento.nombre} style={{ height: "68px" }} />
      </Flex>

      <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="start">
        <VStack align="start" spacing={1} gap="0">
          <Box bg="black" color="white" px={2} py={1} borderRadius="full">
            <Text fontSize="xs">{formattedValorNombre}</Text>
          </Box>
          <Text fontWeight="medium" fontSize="16px" textAlign={"start"}>
            {Descuento.nombre}
          </Text>
          <Text
            fontWeight="bold"
            color={artDesct > 0 ? "green.500" : "inherit"}
          >
            ${Math.ceil(Number(producto.precioFinal))}.00/u.
          </Text>
          {artDesct > 0 && (
            <Text fontSize="xs" color="green.500" style={{ textAlign: "left", textWrap: "nowrap" }}>
              {artDesct}% descuento aplicado
            </Text>
          )}

          {/* Información sobre unidad de medida */}
          {Descuento.Medida === '/Kg' && medida.attributes?.GrsPorcion > 0 && (
            <Text fontSize="xs" color="gray.500" style={{ textAlign: "left", textWrap: "nowrap" }}>
              {stock} porción(es) ({((medida.attributes.GrsPorcion * stock) / 1000).toFixed(2)} kg)
            </Text>
          )}
          {Descuento.Medida === 'ud.' && (
            <Text fontSize="xs" color="gray.500" style={{ textAlign: "left", textWrap: "nowrap" }}>
              {stock} unidad(es)
            </Text>
          )}
        </VStack>
      </Flex>

      <Flex
        flex={1}
        justify="center"
        alignItems="center"
        mb={4}
        overflow="hidden"
        borderRadius="full"
        bg="black"
        color="white"
        width="100%"
        maxW="150px"
      >
        <Button
          onClick={decrementarStock}
          bg="black"
          color="white"
          borderRadius="none"
          height="100%"
          width="33%"
          _hover={{ bg: "gray.800" }}
          _active={{ bg: "gray.700" }}
        >
          -
        </Button>
        <Box
          bg="white"
          height="44px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="solid black 2px"
          width="33%"
          padding={".2rem"}
        >
          <Text fontSize="lg" color="black" fontWeight="bold">
            {stock}
          </Text>
        </Box>
        <Button
          onClick={incrementarStock}
          bg="black"
          color="white"
          borderRadius="none"
          height="100%"
          width="33%"
          _hover={{ bg: "gray.800" }}
          _active={{ bg: "gray.700" }}
        >
          +
        </Button>
      </Flex>
    </HStack>
  );
};

export default BagCard;