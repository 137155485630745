import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  VStack,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import carne from "../assets/CarnePro.png"; // Imagen por defecto
import Quality from "../assets/insignias/QualityInsg.png";
import Quality1 from "../assets/insignias/QualityInsg2.png";
import Quality2 from "../assets/insignias/QualityInsg3.png";

const URL_BASE = process.env.REACT_APP_API_BASE;

const ProductCard = ({ product, Categoria, SubCat }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const formatPrice = (price) => {
    if (typeof price !== "number") return price;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const noSelectStyle = {
    userselect: "none",
    webkituserselect: "none",
    mozuserselect: "none",
    msuserselect: "none",
  };

  const getInsigniaImage = (id) => {
    switch (id) {
      case 1:
        return Quality;
      case 2:
        return Quality1;
      case 3:
        return Quality2;
      default:
        return null;
    }
  };

  const nextImage = (e) => {
    e.preventDefault(); // Prevenir navegación
    e.stopPropagation(); // Prevenir propagación del evento
    if (product?.img?.data && product.img.data.length > 0) {
      setCurrentImageIndex((prev) =>
        prev === product.img.data.length - 1 ? 0 : prev + 1
      );
    }
  };

  const prevImage = (e) => {
    e.preventDefault(); // Prevenir navegación
    e.stopPropagation(); // Prevenir propagación del evento
    if (product?.img?.data && product.img.data.length > 0) {
      setCurrentImageIndex((prev) =>
        prev === 0 ? product.img.data.length - 1 : prev - 1
      );
    }
  };

  // Obtener la URL de la imagen actual o usar la imagen por defecto
  const getCurrentImageUrl = () => {
    try {
      if (
        product?.img?.data &&
        product.img.data.length > 0 &&
        product.img.data[currentImageIndex]?.attributes?.url
      ) {
        const imageUrl = product.img.data[currentImageIndex].attributes.url;
        // Verificar que la URL sea válida
        if (imageUrl.startsWith("http")) {
          return imageUrl;
        }
      }
      return carne; // Imagen por defecto
    } catch (error) {
      console.error("Error al obtener URL de imagen:", error);
      return carne; // Imagen por defecto en caso de error
    }
  };

  // Verificar si hay múltiples imágenes
  const hasMultipleImages = product?.img?.data && product.img.data.length > 1;
console.log(product.medida, "buscar product.medida");

  return (
    <Box
      as={Number(product?.stock) === 0 ? "div" : NavLink}
      to={
        Number(product?.stock) === 0
          ? undefined
          : `/ProdDetalle/${product?.id}`
      }
      className="card"
      position="relative"
      {...noSelectStyle}
      id="irProductuCard"
      cursor={Number(product?.stock) === 0 ? "not-allowed" : "pointer"}
      opacity={Number(product?.stock) === 0 ? 0.7 : 1}
    >
      <VStack spacing={0} alignItems="flex-start">
        {product?.descPorciento != null && product?.descPorciento !== 0 && (
          <Box
            position="absolute"
            top="16px"
            right="16px"
            bg="#318215"
            color="white"
            px="1"
            py=".5"
            borderRadius="md"
            fontSize="sm"
            fontWeight="bold"
            zIndex="1"
          >
            -{product?.descPorciento}%
          </Box>
        )}

        <Box className="image_container" position="relative">
          <Image
            src={getCurrentImageUrl()}
            alt={product?.nombre || "Producto"}
            width="100%"
            height="100%"
            loading="lazy"
            objectFit="cover"
          />

          {/* Controles del carrusel - solo se muestran si hay múltiples imágenes */}
          {hasMultipleImages && (
            <>
              <IconButton
                icon={<ChevronLeft size={20} />}
                onClick={prevImage}
                position="absolute"
                left="0.5"
                top="50%"
                transform="translateY(-50%)"
                size="sm"
                bg="white"
                opacity="0.8"
                _hover={{ opacity: 1 }}
                zIndex="2"
                rounded="full"
              />
              <IconButton
                icon={<ChevronRight size={20} />}
                onClick={nextImage}
                position="absolute"
                right="0.5"
                top="50%"
                transform="translateY(-50%)"
                size="sm"
                opacity="0.8"
                _hover={{ opacity: 1 }}
                zIndex="2"
                rounded="full"
              />

              {/* Indicadores de posición */}
              <Flex
                position="absolute"
                bottom="2"
                left="50%"
                transform="translateX(-50%)"
                gap="2"
                zIndex="2"
              >
                {product.img.data.map((_, index) => (
                  <Box
                    key={index}
                    w="2"
                    h="2"
                    borderRadius="full"
                    bg={
                      currentImageIndex === index ? "white" : "whiteAlpha.600"
                    }
                  />
                ))}
              </Flex>
            </>
          )}

          <VStack
            position="absolute"
            top="10px"
            left="10px"
            spacing={2}
            alignItems="flex-start"
          >
            {product?.insignias?.data?.map((insignia) => {
              const insigniaImage = getInsigniaImage(insignia.id);
              return insigniaImage ? (
                <Image
                  key={insignia.id}
                  src={insigniaImage}
                  alt={`Insignia ${insignia.id}`}
                  width="24px"
                  height="24px"
                />
              ) : null;
            })}
          </VStack>
        </Box>

        <Box>
          <Text fontSize="12px" color="#767676">
            {Categoria
              ? Categoria.replace(/Productos en|Productos/g, "") + "/"
              : ""}
            {SubCat || "texto subcat"}
          </Text>
        </Box>

        <Box height="42px">
          <Text
            noOfLines={2}
            fontSize="1rem"
            fontWeight={700}
            textTransform="capitalize"
            textAlign="left"
            lineHeight="shorter"
            mb="3rem"
          >
            {product?.nombre}
          </Text>
        </Box>

        <Box className="price">
          {product?.descPorciento != null && product?.descPorciento !== 0 ? (
            <VStack alignItems="left" gap={0}>
              <Text as="s" fontSize="10px" color="gray.500" fontWeight="400">
                ${formatPrice(Number(product?.precioKG))} {product?.medida || "/Kg" }
              </Text>
              <Text color="#318215" fontWeight="bold">
                $
                {formatPrice(
                  calculateDiscountedPrice(
                    Number(product?.precioKG),
                    product?.descPorciento
                  )
                )}{" "}
                {product?.medida}
              </Text>
            </VStack>
          ) : (
            <Text>${formatPrice(Number(product?.precioKG))} {product?.medida || "/Kg" }</Text>
          )}
        </Box>
      </VStack>

      <Flex className="action">
        <Button
          as={Number(product?.stock) === 0 ? "button" : NavLink}
          to={
            Number(product?.stock) === 0
              ? undefined
              : `/ProdDetalle/${product?.id}`
          }
          bgColor="#CA0017"
          color="white"
          width="100%"
          borderRadius="24px"
          height="35px"
          id="irProductuBoton"
          isDisabled={Number(product?.stock) === 0}
          opacity={Number(product?.stock) === 0 ? 0.5 : 1}
          cursor={Number(product?.stock) === 0 ? "not-allowed" : "pointer"}
          _hover={{
            bgColor: "#a40013",
          }}
        >
          {Number(product?.stock) === 0 ? "Sin stock" : "Ir al producto"}
        </Button>
      </Flex>
    </Box>
  );
};

export default ProductCard;
