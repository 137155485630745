import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleCallback, updatePrivacyAcceptance } from '../../Redux/Slice';
import {
  Box,
  VStack,
  Spinner,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  Checkbox,
  useToast,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { ExternalLinkIcon, WarningIcon } from '@chakra-ui/icons';

const GoogleCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(true);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Función para detectar dispositivos iOS
  const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // Función para limpiar la URL y extraer el código de manera más robusta
  const extractGoogleCode = (search) => {
    const urlParams = new URLSearchParams(search);
    let code = urlParams.get('code');
    
    // En iOS, a veces el código viene con caracteres extra o encoding incorrecto
    if (code && isIOS()) {
      // Eliminar posibles caracteres especiales y espacios
      code = decodeURIComponent(code.trim());
      // Asegurarse de que no haya caracteres inválidos
      code = code.replace(/[^\w\-\.\~\+\/]*/g, '');
    }
    
    return code;
  };

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = extractGoogleCode(location.search);
        
        if (!code) {
          throw new Error('No se recibió el código de autenticación de Google');
        }

        // Agregar log específico para iOS
        if (isIOS()) {
          console.log('Dispositivo iOS detectado - Código extraído:', code);
        }

        await handleGoogleLogin(code);
      } catch (error) {
        console.error('Error en callback:', error);
        handleError(error);
      }
    };

    handleCallback();
  }, [location]);

  const handleGoogleLogin = async (code) => {
    try {
      setIsProcessing(true);
      
      // Agregar headers específicos para iOS si es necesario
      const extraConfig = isIOS() ? {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      } : {};

      const result = await dispatch(handleGoogleCallback({
        code: code,
        acceptPrivacy: false,
        ...extraConfig
      })).unwrap();

      if (result?.error === 'invalid_grant') {
        throw new Error('invalid_grant');
      }

      if (result.user.poli_priv) {
        checkUserInfo(result.user);
        return;
      }

      if (!result.user.poli_priv && !result.user.lastLoginDate) {
        setCurrentUser(result.user);
        setShowPrivacyModal(true);
        setIsProcessing(false);
        return;
      }

      checkUserInfo(result.user);

    } catch (error) {
      handleError(error);
    } finally {
      if (isProcessing) {
        setIsProcessing(false);
      }
    }
  };

  const handleError = (error) => {
    setIsProcessing(false);
    console.error('Error durante el login:', error);

    let errorMessage = 'No se pudo completar el inicio de sesión con Google';
    let shouldRedirect = true;

    if (error.message?.includes('invalid_grant') || 
        error.response?.data?.error === 'invalid_grant') {
      errorMessage = 'La sesión ha expirado. Por favor, intenta iniciar sesión nuevamente.';
    } else if (error.response?.data?.error?.message) {
      errorMessage = error.response.data.error.message;
    }

    // Manejo especial para errores en iOS
    if (isIOS() && error.response?.status === 400) {
      errorMessage = 'Error de autenticación en dispositivo iOS. Por favor, intenta nuevamente.';
      // Agregar más detalles al log para debug en iOS
      console.log('Error detallado iOS:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        headers: error.response?.headers
      });
    }

    toast({
      title: "Error de autenticación",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
      containerStyle: {
        marginBottom: "80px",
      },
    });

    if (shouldRedirect) {
      navigate('/Login', { state: { error: errorMessage } });
    }
  };

  const handlePrivacyAcceptance = async () => {
    if (!privacyAccepted || !currentUser) {
      toast({
        title: "Políticas de privacidad",
        description: "Debes aceptar las políticas de privacidad para continuar",
        status: "warning",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      return;
    }

    setIsProcessing(true);
    try {
      await dispatch(updatePrivacyAcceptance({
        userId: currentUser.id,
        accepted: true
      })).unwrap();
      
      checkUserInfo(currentUser);
    } catch (error) {
      console.error('Error al actualizar políticas:', error);
      toast({
        title: "Error",
        description: "No se pudo completar el proceso. Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
    } finally {
      setIsProcessing(false);
      setShowPrivacyModal(false);
    }
  };

  const checkUserInfo = (user) => {
    if (!user.username || !user.email || !user.direccion || !user.telefono) {
      navigate('/PersonalData');
    } else {
      navigate('/');
    }
  };

  return (
    <>
      {isProcessing && !showPrivacyModal ? (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
          <VStack spacing={4}>
            <Spinner size="xl" color="blue.500" />
            <Text>Procesando inicio de sesión con Google...</Text>
            {isIOS() && (
              <Text fontSize="sm" color="gray.500">
                Optimizando para dispositivo iOS...
              </Text>
            )}
          </VStack>
        </Box>
      ) : null}

      <Modal 
        isOpen={showPrivacyModal} 
        onClose={() => {}}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack spacing={2}>
              <Icon as={WarningIcon} color="orange.500" />
              <Text>Políticas de Privacidad</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>
                Para continuar usando nuestros servicios, es necesario que aceptes nuestras políticas de privacidad.
              </Text>
              <Link
                href="https://www.deternera.com/privacy-policy"
                isExternal
                color="blue.500"
                display="flex"
                alignItems="center"
              >
                Ver políticas de privacidad <ExternalLinkIcon mx="2px" />
              </Link>
              <Checkbox
                isChecked={privacyAccepted}
                onChange={(e) => setPrivacyAccepted(e.target.checked)}
              >
                He leído y acepto las políticas de privacidad
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handlePrivacyAcceptance}
              isLoading={isProcessing}
              isDisabled={!privacyAccepted}
            >
              Aceptar y Continuar
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                navigate('/Login');
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GoogleCallback;